<template>
  <div class="label label-right relative isolate flex items-center gap-5">
    <IconsActionsSearch />
    <FormKit
      v-model="state.query"
      autocomplete="off"
      type="text"
      outer-class="!m-0 w-full"
      input-class="!placeholder-black/20 text-black/80 font-normal !border-0 text-base xl:text-xl"
      :placeholder="$t('Search placeholder')"
      :delay="500"
    />
    <TransitionGroup
      v-if="!isEmpty(results)"
      name="slide-in-list"
      tag="ul"
      class="list absolute left-0 top-1/2 -z-10 w-full rounded-br-3xl bg-white pt-7"
    >
      <li
        v-for="result in results"
        :key="result.id"
        class="border-t border-black/10 px-7 py-3"
      >
        <!-- FIXME last element behind scrollTo button on medium viewports -->
        <NuxtLink
          v-if="result.__typename !== 'Evenement'"
          class="link-header block"
          :to="getRouteTo(result)"
        >
          {{ result.title }}
        </NuxtLink>
        <a
          v-else
          class="link-header"
          :href="result.external_url"
        >{{ result.title }}</a>
      </li>
      <li class="px-7 py-3">
        <NuxtLink
          class="block pt-0"
          :to="localePath({name: 'search', query: {q: state.query}})"
        >
          {{ $t('Show results') }}
        </NuxtLink>
      </li>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import {isEmpty} from 'lodash-es';
import {useSearch} from '~/composables/useSearch';

const localePath = useLocalePath();

const state = reactive({
  query: '',
  limit: 2,
});

const {searchResults, getRouteTo} = useSearch(state);

const results = computed(() => {
  const data = searchResults.value;
  return [...(data?.news ?? []), ...(data?.events ?? []), ...(data?.ambitions ?? [])];
});
</script>
