<template>
  <div>
    <section class="container">
      <Hero>
        <template #top-item>
          <Search class="w-1/3 px-3 max-lg:hidden xl:px-6" />
        </template>
        <template #default>
          <SofieText
            class="hero-title green-dot-suffix"
            :value="currentPage.template.teaser"
          />
          <SofieText
            class="max-lg:hidden lg:pt-2.5 lg:text-white"
            :value="currentPage.template.home_text"
          />
        </template>
      </Hero>
      <div class="hidden lg:flex lg:-translate-y-6 lg:flex-col lg:items-center lg:gap-5">
        <Btn
          :to="`#${$t('Ambitions').toLowerCase()}`"
          class="!mt-0"
          :label="$t('Go to our ambitions')"
          :short-label="$t('Our ambitions')"
          centered
        />
      </div>
    </section>

    <ScrollAnchor :id="$t('Ambitions').toLowerCase()" />
    <section class="container pt-5 lg:pt-0">
      <h2 class="heading max-lg:pb-5">
        {{ $t('Our ambitions') }}
      </h2>
      <AmbitionGallery :ambitions="ambitions" />
      <Btn
        :to="localePath('ambitions')"
        :label="$t('Go to our ambitions')"
        class="lg:hidden"
      />
    </section>

    <section
      v-if="articles.length && locale === 'nl'"
      class="section-container lg:pt-10"
    >
      <h2 class="heading">
        {{ $t('News') }}
      </h2>
      <NewsHighlight :articles="articles.slice(0, 5)" />
      <Btn
        :to="localePath('news')"
        :label="$t('Go to more news')"
        :short-label="$t('More news')"
      />
    </section>

    <section class="bg-green overflow-hidden">
      <div class="section-container max-lg:pt-4">
        <h2 class="heading max-lg:pb-4">
          {{ $t('Agenda') }}
        </h2>
        <EventCalendar
          :max="4"
          :events="events"
        />
        <Btn
          :to="localePath('events')"
          :label="$t('Go to extensive agenda')"
          :short-label="$t('Extensive agenda')"
          class="!mt-0"
        />
      </div>
    </section>

    <section class="overflow-hidden">
      <div class="section-container lg:grid-side-content">
        <div>
          <h2 class="heading max-lg:pb-5">
            {{ currentPage.template.sector_title }}
          </h2>
          <Hero
            class="lg:hidden"
            image="/images/hero2.png"
          >
            <SofieText
              class="hero-title"
              :value="factsTitle"
            />
          </Hero>
          <SofieText
            class="max-lg:hidden"
            :value="currentPage.template.sector_text"
          />
          <BtnTransparent
            :to="'/de-sector'"
            :label="$t('More about the sector')"
            :short-label="$t('About the sector')"
          />
        </div>

        <InfoView
          class="max-lg:hidden"
          :content="currentPage.template"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import {useArticles} from '~/composables/useArticles';
import {useEvents} from '~/composables/useEvents';
import BtnTransparent from '~/components/BtnTransparent.vue';
import {useAmbitionsQuery} from '~/graphql/graphql';

definePageMeta({
  auth: false,
  middleware: ['sofie-page'],
});

const currentPage = useSofiePage();
const localePath = useLocalePath();
const {locale} = useI18n();

const factsTitle = '<p>Wereldspeler</p>';

const {articles} = useArticles();
const {events} = useEvents();

const {result: ambitionsQueryResult} = useAmbitionsQuery();
const ambitions = computed(() => ambitionsQueryResult.value?.ambitions ?? []);
</script>
